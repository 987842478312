import {base64encode,base64decode} from "@/common/base64"

export default {
    mounted() {
        // 如果没有sso，则不显示sso的登录页面
        const force_admin = this.$route.query.admin;
        if (!this.$organization.sso || force_admin) {
            this.admin_login = true;
            this.ready = true;
            this.page_ready = true;
        } else {
            this.handleMounted();
        }
    },
    computed: {
        oauth() {
            return !!this.$organization.sso;
        }
    },
    methods: {
        handleSSOSuccess(res){
            this.setUserInfo(res.data.data);
            //成功后，跳转的话，需要将原来编码的返回地址decode
            const _t = this.getReturnUrlT();
            window.location.href = _t || '/portal';
        },
        jumpToSSO() {
            // 跳转到SSO登录页面
            const _t = this.$route.query._t?encodeURIComponent(base64encode(this.$route.query._t)):'';
            const state = '&state=' + _t;
            let return_url;
            if (this.$organization.sso_type === 'cas') {
                return_url = this.$organization.signin_url + (this.$route.query._t ? '?_tt=' + _t : '');
                window.location.href = this.$organization.sso + encodeURIComponent(return_url);
            } else {
                return_url = this.$organization.signin_url ;
                window.location.href = this.$organization.sso + encodeURIComponent(return_url) + state;
            }
        },
        getReturnUrlT() {
            let _t =decodeURIComponent(this.$route.query._t);
            if (this.$route.query._tt) {
                _t = decodeURIComponent(base64decode(decodeURIComponent(this.$route.query._tt)));
            } else if (this.$route.query.state){
                _t = base64decode(decodeURIComponent(this.$route.query.state));
            }
            return _t;
        },
        jumpToSignIn() {
            const _t = this.getReturnUrlT();
            if (_t) {
                this.$router.replace('/sign-in?_t=' + encodeURIComponent(_t));
            } else {
                this.$router.replace('/sign-in')
            }
        },
        handleMounted() {
            const vm = this;
            // 设置了sso怎判断是否有code或者ticket
            if(this.$route.query.ticket) { // 如果有code或者ticket，说明从sso返回来的信息，主要是 CAS
                //这里的t还是加密状态，否则api访问cas会错误
                this.$api('SSO?ticket='+this.$route.query.ticket+"&_t="+(this.$route.query._tt || ""), null, 1).then(res => {
                    if (res.data.success) {
                        vm.handleSSOSuccess(res);
                    } else {
                        vm.$messageBox.error('错误的SSO返回信息,重新登录', null, () => {
                            this.jumpToSignIn();
                        });
                    }
                }).catch(() => {
                    this.ready = true;
                    this.page_ready = true;
                });
            } else if (this.$route.query.code) {
                // 这里是有code的情况，说明是OAuth2的情况，返回url放在state里面
                const _t = this.$route.query.state?encodeURIComponent(this.$route.query.state):null;
                this.$api('SSO', {code: this.$route.query.code, state: _t }, 1).then(res => {
                    if (res.data.success) {
                        if (res.data.data.error) {
                            this.show_login_error = true;
                            this.error_message = res.data.data.message;
                        } else {
                            vm.handleSSOSuccess(res);
                        }
                    } else {
                        this.$messageBox.error(res.data.text, null, () => {
                            this.jumpToSignIn()
                        })
                    }
                }).catch(() => {
                    this.ready = true;
                    this.page_ready = true;
                });

            } else {
                // 这里是没有sso返回值，则需要检测一下是否force，force则直接跳转sso登录
                if (this.$organization.sso_force || this.$route.query.from === 'portal') {
                    this.ready = false;
                    this.page_ready=false;
                    vm.jumpToSSO();
                } else {
                    this.ready = true;
                    this.page_ready = true;
                }
            }
        }
    },
}
